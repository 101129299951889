import React from 'react';
import { Progress, CardText } from 'reactstrap';
import { VelibStation } from '../../../../../_clients/velibMetropoleClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faFlagCheckered, faBolt, faWalking, faWrench } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  title: string;
  stations: VelibStation[];
  mode: 'departure' | 'arrival';
}

export class VelibStationsList extends React.Component<Props> {
  private readonly walkSpeedInMeters: number = 66; // ~ 4 km/h

  public render() {
    const { title, stations, mode } = this.props;

    return (
      <>
        <CardText>
          <small className="text-muted">{title} stations:</small>
        </CardText>
        {stations.map((station) => {
          const capacity = station.capacity;
          const maintenance = capacity - station.numdocksavailable - station.mechanical - station.ebike;
          const dist = Number(station.dist);
          const time = dist / this.walkSpeedInMeters;
          const min = Math.floor(time);
          const sec = (time - min) * 60;
          return (
            <div key={station.stationcode}>
              <div className="text-center">
                {station.name}
                &nbsp;- &nbsp;{dist.toFixed(0)}m
                {time > 0 && (
                  <>
                    &nbsp;(
                    {time > 0 && `+${min.toFixed(0)}m${sec.toFixed(0).padStart(2, '0')}s`}
                    &nbsp;
                    <FontAwesomeIcon icon={faWalking} />)
                  </>
                )}
              </div>
              <Progress multi={true}>
                <>
                  {this.renderProgressBar(
                    'success',
                    mode === 'departure' ? station.mechanical : station.numdocksavailable,
                    capacity,
                    mode === 'departure' ? faBicycle : faFlagCheckered
                  )}
                  {mode === 'departure' && this.renderProgressBar('info', station.ebike, capacity, faBolt)}
                  {mode === 'departure' && this.renderProgressBar('warning', maintenance, capacity, faWrench)}
                  {this.renderProgressBar(
                    'danger',
                    mode === 'departure' ? station.numdocksavailable : station.mechanical + station.ebike + maintenance,
                    capacity,
                    mode === 'departure' ? faFlagCheckered : faBicycle
                  )}
                </>
              </Progress>
            </div>
          );
        })}
      </>
    );
  }

  renderProgressBar(color: string, value: number, total: number, icon: IconProp) {
    return (
      <Progress bar={true} color={color} value={`${(value * 100) / total}`}>
        <span>
          {value}
          &nbsp;
          <FontAwesomeIcon icon={icon} />
        </span>
      </Progress>
    );
  }
}
