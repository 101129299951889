import { Reducer } from 'redux';
import { Place } from '../../../_models/Place';
import { UserActions } from '../../user/actions/userActions';
import { PlacesActions } from '../actions/placesActions';

const defaultState: { [key: string]: Place } = {};

export const reducer: Reducer<{ [key: string]: Place }, PlacesActions | UserActions> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'ADD_PLACE':
      return {
        ...state,
        [action.payload.place.name]: action.payload.place,
      };
    case 'DELETE_PLACE':
      const copy = { ...state };
      delete copy[action.payload.placeKey];
      return copy;
    case 'LOAD_CONFIG_SUCCESS':
      return {
        ...action.payload.state.places,
      };
    default:
      return state;
  }
};
