import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { getVelibStations } from '../../../../_clients/velibMetropoleClient';
import { getTrainStations, trainStationsMap } from '../../../../_clients/trainClient';
import { Place } from '../../../../_models/Place';

interface Props {
  onComplete: () => void;
  addPlace: (place: Place) => void;
}

const PlaceForm = (props: Props) => {
  const velibStations = getVelibStations();
  const trainStations = getTrainStations();

  const [placeState, setPlaceState] = useState({
    inputName: '',
    inputVelibStationId: '',
    inputTrainStationId: '',
    inputShuttleService: '',
  });

  const handlePlaceChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) =>
    setPlaceState({
      ...placeState,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const velibStation = placeState.inputVelibStationId
      ? velibStations.find((s) => s.station_id === Number(placeState.inputVelibStationId))
      : undefined;

    const trainStation = placeState.inputTrainStationId ? trainStationsMap[placeState.inputTrainStationId] : undefined;

    props.addPlace({
      name: placeState.inputName,
      preferredTrainStation: trainStation
        ? {
            name: trainStation,
            stationId: placeState.inputTrainStationId,
          }
        : undefined,
      preferredVelibStation: velibStation
        ? {
            name: velibStation.name,
            stationId: velibStation.station_id,
          }
        : undefined,
      preferredShuttleService: (placeState.inputShuttleService as 'MSShuttle') || undefined,
    });

    props.onComplete();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label className="required-label" for="inputName">
          Name
        </Label>
        <Input
          id="inputName"
          name="inputName"
          onChange={handlePlaceChange}
          required={true}
          value={placeState.inputName}
          type="text"
        />
      </FormGroup>
      <FormGroup>
        <Label className="required-label" for="inputVelibStationId">
          Velib Station
        </Label>
        <Input
          id="inputVelibStationId"
          name="inputVelibStationId"
          onChange={handlePlaceChange}
          value={placeState.inputVelibStationId}
          type="select"
        >
          <option value={''}>--NO VELIB--</option>
          {velibStations.map((s) => {
            return (
              <option key={s.station_id} value={s.station_id}>
                {s.name}
              </option>
            );
          })}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label className="required-label" for="inputTrainStationId">
          Train station
        </Label>
        <Input
          id="inputTrainStationId"
          name="inputTrainStationId"
          onChange={handlePlaceChange}
          value={placeState.inputTrainStationId}
          type="select"
        >
          <option value={''}>--NO TRAIN--</option>
          {trainStations.map((s, i) => {
            return (
              <option key={s.stationId} value={s.stationId}>
                {s.displayName}
              </option>
            );
          })}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label className="required-label" for="inputShuttleService">
          Shuttle service
        </Label>
        <Input
          id="inputShuttleService"
          name="inputShuttleService"
          onChange={handlePlaceChange}
          value={placeState.inputShuttleService}
          type="select"
        >
          <option value={''}>--NO SHUTTLE--</option>
          <option value={'MSShuttle'}>MS Shuttle</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Button type="submit">Save</Button>
      </FormGroup>
    </Form>
  );
};

export default PlaceForm;
