import React from 'react';
import { Navbar as BootstrapNavbar, Nav, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faBus, faRoute } from '@fortawesome/free-solid-svg-icons';
import { NavLink as NavLinkRouter } from 'react-router-dom';

interface State {
  isMobileScreen: boolean;
}

class Navbar extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isMobileScreen: false,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    let mobile = window.innerWidth <= 576;
    if (mobile !== this.state.isMobileScreen) {
      this.setState({ isMobileScreen: mobile });
    }
  }

  public render() {
    return (
      <BootstrapNavbar
        color="light"
        light={true}
        fixed={this.state.isMobileScreen ? 'bottom' : 'top'}
        expand="xs"
        className={!this.state.isMobileScreen ? `d-none d-sm-flex` : `d-xs-block d-sm-none`}
        style={!this.state.isMobileScreen ? undefined : { paddingBottom: 'calc(.5rem + env(safe-area-inset-bottom))' }}
      >
        <NavbarBrand className={`d-none d-sm-block`}>
          <FontAwesomeIcon icon={faBus} />
          &nbsp;Shuttle
        </NavbarBrand>
        <Nav navbar={true} className="me-auto">
          <NavItem>
            <NavLink activeClassName={`active`} tag={NavLinkRouter} to="/">
              <FontAwesomeIcon icon={faRoute} />
              &nbsp; My journeys
            </NavLink>
          </NavItem>
        </Nav>
        <Nav navbar={true} className="d-sm-block my-sm-0">
          <NavItem>
            <NavLink activeClassName={`active`} tag={NavLinkRouter} to="/settings">
              <FontAwesomeIcon icon={faUserCog} />
            </NavLink>
          </NavItem>
        </Nav>
      </BootstrapNavbar>
    );
  }
}

export default Navbar;
