import React from 'react';
import { connect } from 'react-redux';
import {
  VelibStation,
  getVelibStationsAroundAsync,
  getVelibStationInfo,
} from '../../../../../_clients/velibMetropoleClient';
import { VelibCard } from './VelibCard';
import { Journey } from '../../../../../_models/Journey';
import { Place } from '../../../../../_models/Place';
import { ApplicationState } from '../../../../../store/rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';

interface State {
  lastUpdateDate: Date;
  fromVelibStations: VelibStation[];
  toVelibStations: VelibStation[];
  fetching: boolean;
}

interface Props extends RouteComponentProps<{}> {
  selectedJourneyName?: string;
  places: { [key: string]: Place };
  journeys: Journey[];
}

class VelibContainer extends React.Component<Props, State> {
  autoRefreshInterval?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      lastUpdateDate: new Date(),
      fromVelibStations: [],
      toVelibStations: [],
      fetching: true,
    };
  }

  async componentDidMount() {
    await this.fetchVelib();
    this.registerInterval();
  }

  registerInterval() {
    this.autoRefreshInterval = window.setInterval(() => {
      this.setState({ fetching: true }, this.fetchVelib);
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.autoRefreshInterval);
  }

  fetchVelib = async () => {
    const { journeys, selectedJourneyName, places } = this.props;

    const selectedJourney = journeys.find((j) => j.name === selectedJourneyName);

    if (selectedJourney) {
      const fromPlace = places[selectedJourney.fromPlaceName];
      const toPlace = places[selectedJourney.toPlaceName];

      if (fromPlace.preferredVelibStation && toPlace.preferredVelibStation) {
        const fromStationInfo = getVelibStationInfo(fromPlace.preferredVelibStation.stationId);
        const toStationInfo = getVelibStationInfo(toPlace.preferredVelibStation.stationId);
        const fromVelibStations = await getVelibStationsAroundAsync({
          latitude: fromStationInfo.lat,
          longitude: fromStationInfo.lon,
        });
        const toVelibStations = await getVelibStationsAroundAsync({
          latitude: toStationInfo.lat,
          longitude: toStationInfo.lon,
        });
        this.setState({
          toVelibStations: toVelibStations,
          fromVelibStations: fromVelibStations,
          lastUpdateDate: new Date(),
          fetching: false,
        });
      }
    }
  };

  async componentDidUpdate(prevProps: Props) {
    if (this.props.selectedJourneyName !== prevProps.selectedJourneyName) {
      clearInterval(this.autoRefreshInterval);
      this.setState({
        fetching: true,
        fromVelibStations: [],
        toVelibStations: [],
      });
      await this.fetchVelib();
      this.registerInterval();
    }
  }

  public render() {
    const { lastUpdateDate, fromVelibStations, toVelibStations, fetching } = this.state;

    const { journeys, selectedJourneyName } = this.props;

    const selectedJourney = journeys.find((j) => j.name === selectedJourneyName);

    return (
      <>
        {selectedJourney && (
          <VelibCard
            fromTitle={selectedJourney.fromPlaceName}
            toTitle={selectedJourney.toPlaceName}
            fromStations={fromVelibStations}
            toStations={toVelibStations}
            lastUpdateDate={lastUpdateDate}
            fetching={fetching}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(applicationState: ApplicationState) {
  return {
    places: applicationState.places,
    journeys: applicationState.journeys,
    selectedJourneyName: applicationState.user.selectedJourneyName,
  };
}

export default withRouter(connect(mapStateToProps)(VelibContainer));
