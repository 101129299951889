import { ActionCreator } from 'redux';
import { PayloadAction } from '../../PayloadAction';
import { ApplicationState } from '../../rootReducer';

export const SELECT_JOURNEY = 'SELECT_JOURNEY';

export const SHARE_CONFIG = 'SHARE_CONFIG';

export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';

interface SelectJourney extends PayloadAction<typeof SELECT_JOURNEY, { journeyName: string }> {}
interface ShareConfig extends PayloadAction<typeof SHARE_CONFIG, { code: string; validUntil: number }> {}
interface LoadConfigSuccess extends PayloadAction<typeof LOAD_CONFIG_SUCCESS, { state: ApplicationState }> {}

export const selectJourney: ActionCreator<SelectJourney> = (journeyName: string) => {
  return {
    type: SELECT_JOURNEY,
    payload: {
      journeyName,
    },
  };
};

export const shareConfig: ActionCreator<ShareConfig> = (code: string, validUntil: number) => {
  return {
    type: SHARE_CONFIG,
    payload: {
      code,
      validUntil,
    },
  };
};

export const loadConfigSuccess: ActionCreator<LoadConfigSuccess> = (state: ApplicationState) => {
  return {
    type: LOAD_CONFIG_SUCCESS,
    payload: {
      state,
    },
  };
};

export type UserActions = SelectJourney | ShareConfig | LoadConfigSuccess;
