import trainStation from './trainStations.json';

export const trainStationsMap: TrainStationsList = trainStation;

export const getTrainStations = (): TrainStationInfo[] => {
  const result: { stationId: string; displayName: string }[] = [];
  const list: TrainStationsList = trainStation;
  for (const key in trainStation) {
    if (list.hasOwnProperty(key)) {
      result.push({
        stationId: key,
        displayName: list[key],
      });
    }
  }
  return result.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const getNextTrainsAsync = async (stationId: string): Promise<TrainInfo[]> => {
  const url = `https://shuttle-api.toubiana.dev/api/RealTimeSncf?stationId=${stationId}`;
  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error(response.statusText || `${response.status}`);
  }

  return await response.json();
};

export interface TrainInfo {
  date: string;
  num: string;
  miss: string;
  term: string;
  etat?: string;
}

export type TrainStationInfo = {
  stationId: string;
  displayName: string;
};

export type TrainStationsList = {
  [key: string]: string;
};
