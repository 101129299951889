import { ActionCreator } from 'redux';
import { Journey } from '../../../_models/Journey';
import { PayloadAction } from '../../PayloadAction';

export const ADD_JOURNEY = 'ADD_JOURNEY';

export const DELETE_JOURNEY = 'DELETE_JOURNEY';

interface AddJourney extends PayloadAction<typeof ADD_JOURNEY, { journey: Journey }> {}
interface DeleteJourney extends PayloadAction<typeof DELETE_JOURNEY, { index: number }> {}

export const addJourney: ActionCreator<AddJourney> = (journey: Journey) => {
  return {
    type: ADD_JOURNEY,
    payload: {
      journey,
    },
  };
};

export const deleteJourney: ActionCreator<DeleteJourney> = (index: number) => {
  return {
    type: DELETE_JOURNEY,
    payload: {
      index,
    },
  };
};

export type JourneysActions = AddJourney | DeleteJourney;
