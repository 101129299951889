import React from 'react';
import { connect } from 'react-redux';
import { TrainInfo, getNextTrainsAsync } from '../../../../../_clients/trainClient';
import { Journey } from '../../../../../_models/Journey';
import { Place } from '../../../../../_models/Place';
import { ApplicationState } from '../../../../../store/rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { TrainsCard } from './TrainsCard';

interface State {
  fetching: boolean;
  lastUpdateDate: Date;
  nextTrains: TrainInfo[];
}

interface Props extends RouteComponentProps<{}> {
  selectedJourneyName?: string;
  places: { [key: string]: Place };
  journeys: Journey[];
}

class TrainContainer extends React.Component<Props, State> {
  autoRefreshInterval?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      nextTrains: [],
      fetching: true,
      lastUpdateDate: new Date(),
    };
  }

  async componentDidMount() {
    await this.fetch();
    this.registerInterval();
  }

  registerInterval() {
    this.autoRefreshInterval = window.setInterval(() => {
      this.setState({ fetching: true }, this.fetch);
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.autoRefreshInterval);
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.selectedJourneyName !== prevProps.selectedJourneyName) {
      clearInterval(this.autoRefreshInterval);
      this.setState({
        nextTrains: [],
        fetching: true,
      });
      await this.fetch();
      this.registerInterval();
    }
  }

  fetch = async () => {
    const { journeys, selectedJourneyName, places } = this.props;

    const selectedJourney = journeys.find((j) => j.name === selectedJourneyName);

    if (selectedJourney) {
      const fromPlace = places[selectedJourney.fromPlaceName];

      if (fromPlace.preferredTrainStation) {
        const nextTrains = await getNextTrainsAsync(fromPlace.preferredTrainStation.stationId);

        this.setState({
          nextTrains,
          fetching: false,
          lastUpdateDate: new Date(),
        });
      }
    }
  };

  public render() {
    const { nextTrains, lastUpdateDate, fetching } = this.state;

    const { journeys, places, selectedJourneyName } = this.props;

    const selectedJourney = journeys.find((j) => j.name === selectedJourneyName);
    const fromPlace = selectedJourney && places[selectedJourney.fromPlaceName];
    const stationName = fromPlace && fromPlace.preferredTrainStation && fromPlace.preferredTrainStation.name;

    return (
      <>
        <TrainsCard
          stationName={stationName || 'Trains'}
          trains={nextTrains}
          lastUpdateDate={lastUpdateDate}
          fetching={fetching}
        />
      </>
    );
  }
}

function mapStateToProps(applicationState: ApplicationState) {
  return {
    places: applicationState.places,
    journeys: applicationState.journeys,
    selectedJourneyName: applicationState.user.selectedJourneyName,
  };
}

export default withRouter(connect(mapStateToProps)(TrainContainer));
