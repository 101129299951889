import React from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Place } from '../../../../_models/Place';
import { Journey } from '../../../../_models/Journey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onComplete: () => void;
  addJourney: (journey: Journey) => void;
  places: Place[];
}

interface State {
  inputFromPlaceName: string;
  inputToPlaceName: string;
  errorMessage?: string;
}

class JourneyForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputFromPlaceName: props.places[0].name,
      inputToPlaceName: props.places[1].name,
    };
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.state.errorMessage && (
          <Alert color="danger">
            <FontAwesomeIcon icon={faExclamationCircle} />
            &nbsp;{this.state.errorMessage}
          </Alert>
        )}
        <FormGroup>
          <Label className="required-label" for="inputFromPlaceName">
            From
          </Label>
          <Input
            id="inputFromPlaceName"
            name="inputFromPlaceName"
            onChange={this.handleChange}
            value={this.state.inputFromPlaceName}
            type="select"
          >
            {this.props.places.map((p) => {
              return (
                <option key={p.name} value={p.name}>
                  {p.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="required-label" for="inputToPlaceName">
            To
          </Label>
          <Input
            id="inputToPlaceName"
            name="inputToPlaceName"
            onChange={this.handleChange}
            value={this.state.inputToPlaceName}
            type="select"
          >
            {this.props.places
              .filter((p) => p.name !== this.state.inputFromPlaceName)
              .map((p) => {
                return (
                  <option key={p.name} value={p.name}>
                    {p.name}
                  </option>
                );
              })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Button type="submit">Save</Button>
        </FormGroup>
      </Form>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value;
    let inputName = event.target.name;

    const newIndex = this.props.places.findIndex((p) => p.name !== input);

    switch (inputName) {
      default:
        this.setState({
          [inputName]: input,
          inputToPlaceName: inputName === 'inputToPlaceName' ? input : this.props.places[newIndex].name,
          // tslint:disable-next-line:no-any
        } as any);
        break;
    }
  };

  handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.inputFromPlaceName === this.state.inputToPlaceName) {
      this.setState({
        errorMessage: 'Please select 2 different places',
      });
    } else {
      this.props.addJourney({
        name: `${this.state.inputFromPlaceName} => ${this.state.inputToPlaceName}`,
        fromPlaceName: this.state.inputFromPlaceName,
        toPlaceName: this.state.inputToPlaceName,
      });

      this.props.onComplete();
    }
  };
}

export default JourneyForm;
