import React from 'react';
import { Switch, Route } from 'react-router';
import HomePage from './features/home/HomePage';
import SettingsPage from './features/settings/SettingsPage';

export class Routes extends React.Component {
  public render() {
    return (
      <Switch>
        <Route path="/" exact={true} component={HomePage} />
        <Route path="/settings" exact={true} component={SettingsPage} />
      </Switch>
    );
  }
}
