import React from 'react';
import { getNextShuttles, MSShuttle } from '../../../../../_clients/msShuttleClient';
import { ShuttleCard } from './ShuttleCard';

interface State {
  lastUpdateDate: Date;
  shuttles: MSShuttle[];
}

class ShuttleContainer extends React.Component<{}, State> {
  autoRefreshInterval?: number;

  constructor(props: {}) {
    super(props);
    this.state = {
      lastUpdateDate: new Date(),
      shuttles: [],
    };
  }

  componentDidMount() {
    this.fetch();
    this.registerInterval();
  }

  fetch() {
    this.setState({
      shuttles: getNextShuttles(),
      lastUpdateDate: new Date(),
    });
  }

  registerInterval() {
    this.autoRefreshInterval = window.setInterval(() => {
      this.fetch();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.autoRefreshInterval);
  }

  public render() {
    return (
      <>
        <ShuttleCard shuttles={this.state.shuttles} lastUpdateDate={this.state.lastUpdateDate} />
      </>
    );
  }
}

export default ShuttleContainer;
