import React from 'react';
import { Card, CardTitle, CardBody, CardText, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrain, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './TrainsCard.module.css';
import { TrainInfo, trainStationsMap } from '../../../../../_clients/trainClient';

interface Props {
  stationName: string;
  trains: TrainInfo[];
  lastUpdateDate: Date;
  fetching: boolean;
}

export class TrainsCard extends React.Component<Props> {
  public render() {
    const { stationName, trains, lastUpdateDate, fetching } = this.props;
    return (
      <Card>
        <CardBody className={styles.cardBody}>
          <CardTitle className="text-center">
            <p className="lead">
              <FontAwesomeIcon icon={faTrain} />
              &nbsp;{stationName}
            </p>
          </CardTitle>
          <div>
            <CardText>
              {trains.length === 0 && <span className="text-danger">No more train</span>}
              {trains.length > 0 && <small className="text-muted">Next trains:</small>}
            </CardText>
            {trains.length > 0 && (
              <Table className="table" size="sm" striped={true}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Num</th>
                    <th>Miss</th>
                    <th>Term</th>
                    <th>Etat</th>
                  </tr>
                </thead>
                <tbody>
                  {trains.map((train, i) => {
                    return (
                      <tr key={i}>
                        <td>{train.date}</td>
                        <td>{train.num}</td>
                        <td>{train.miss}</td>
                        <td>{trainStationsMap[train.term]}</td>
                        <td>{train.etat || 'on time'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
          <CardText>
            {fetching && (
              <small className="text-muted">
                <FontAwesomeIcon icon={faSpinner} spin={true} />
                &nbsp; loading...
              </small>
            )}
            {!fetching && <small className="text-muted">Last updated {lastUpdateDate.toLocaleString()}</small>}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}
