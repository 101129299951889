export interface ConfigSaveResponse {
  code: string;
  validUntil: string;
}

export const saveConfigAsync = (config: any): Promise<ConfigSaveResponse> => {
  const url = `https://shuttle-api.toubiana.dev/api/SaveJourneys`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(config),
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<ConfigSaveResponse>;
    } else {
      return Promise.reject();
    }
  });
};

export const loadConfigAsync = (code: string): Promise<string> => {
  const url = `https://shuttle-api.toubiana.dev/api/LoadJourneys?code=${code}`;
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.text();
    } else {
      return Promise.reject();
    }
  });
};
