import { Reducer } from 'redux';
import { UserActions } from '../actions/userActions';
import { UserState } from '..';

const defaultState: UserState = {};

export const reducer: Reducer<UserState, UserActions> = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_JOURNEY':
      return {
        ...state,
        selectedJourneyName: action.payload.journeyName,
      };
    case 'SHARE_CONFIG':
      return {
        ...state,
        sharedConfig: {
          code: action.payload.code,
          validUntil: action.payload.validUntil,
        },
      };
    case 'LOAD_CONFIG_SUCCESS':
      return {
        ...action.payload.state.user,
      };
    default:
      return state;
  }
};
