import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { Journey } from '../../../../_models/Journey';

interface Props {
  journeys: Journey[];
  onDelete: (idx: number) => void;
}

const JourneysTable = (props: Props) => {
  return (
    <Table className="table" size="sm" striped={true}>
      <thead>
        <tr>
          <th>Journey</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.journeys.map((journey, i) => {
          return (
            <tr key={i}>
              <td>{`${journey.fromPlaceName} => ${journey.toPlaceName}`}</td>
              <td>
                <Button size="sm" color="danger" onClick={() => props.onDelete(i)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default JourneysTable;
