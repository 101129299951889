import React from 'react';
import JourneyContainer from './components/JourneyContainer';

class HomePage extends React.Component {
  public render() {
    return <JourneyContainer />;
  }
}

export default HomePage;
