import { ReducersMapObject, combineReducers } from 'redux';
import * as Places from './places';
import * as Journeys from './journeys';
import * as User from './user';
import { PayloadAction } from './PayloadAction';

export interface ApplicationState {
  places: Places.State;
  journeys: Journeys.State;
  user: User.State;
}

export const reducers: ReducersMapObject<ApplicationState, PayloadAction> = {
  places: Places.Reducer,
  journeys: Journeys.Reducer,
  user: User.Reducer,
};

export const rootReducer = combineReducers<ApplicationState>(reducers);
