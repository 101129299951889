import React from 'react';
import { Routes } from './Routes';
import Navbar from './common/Navbar';
import { Container } from 'reactstrap';

const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Container fluid={true}>
        <Routes />
      </Container>
    </>
  );
};

export default App;
