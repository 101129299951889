import * as User from './reducers/userReducer';

export interface UserState {
  selectedJourneyName?: string;
  sharedConfig?: ConfigState;
}

export interface ConfigState {
  code: string;
  validUntil: number;
}

export type State = UserState;
export const Reducer = User.reducer;
