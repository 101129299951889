import { ActionCreator } from 'redux';
import { Place } from '../../../_models/Place';
import { PayloadAction } from '../../PayloadAction';

export const ADD_PLACE = 'ADD_PLACE';

export const DELETE_PLACE = 'DELETE_PLACE';

interface AddPlace extends PayloadAction<typeof ADD_PLACE, { place: Place }> {}
interface DeletePlace extends PayloadAction<typeof DELETE_PLACE, { placeKey: string }> {}

export const addPlace: ActionCreator<AddPlace> = (place: Place) => {
  return {
    type: ADD_PLACE,
    payload: {
      place,
    },
  };
};

export const deletePlace: ActionCreator<DeletePlace> = (placeKey: string) => {
  return {
    type: DELETE_PLACE,
    payload: {
      placeKey,
    },
  };
};

export type PlacesActions = AddPlace | DeletePlace;
