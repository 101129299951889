import moment from 'moment';
const shuttleList = ['17:30', '17:43', '17:56', '18:09', '18:22', '18:35', '18:48', '19:01', '19:14', '19:27'];

export const getNextShuttles = (): MSShuttle[] => {
  const dateNow = new Date();
  const timeNowStr = moment(dateNow).format('HH:mm');
  const nextShuttleTimesStr = shuttleList.filter((time) => time > timeNowStr);
  const nextShuttleDates = nextShuttleTimesStr.map((timeStr) => moment(timeStr, 'HH:mm').toDate()).sort();
  return nextShuttleDates.map((startDate) => {
    const garigliano = moment(startDate).add(5, 'm').toDate();
    const balard = moment(startDate).add(12, 'm').toDate();
    return {
      startDate: startDate,
      gariglianoStopDate: garigliano,
      balardStopDate: balard,
    };
  });
};

export interface MSShuttle {
  startDate: Date;
  gariglianoStopDate: Date;
  balardStopDate: Date;
}
