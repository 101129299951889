import React from 'react';
import { Alert } from 'reactstrap';
import { faLightbulb, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlacesTable from './PlacesTable';
import PlaceFormModal from './PlaceFormModal';
import { Place } from '../../../../_models/Place';

interface Props {
  places: Place[];
  addPlace: (place: Place) => void;
  deletePlace: (placeKey: string) => void;
}

const MyPlaces = (props: Props) => {
  return (
    <div>
      <p className="lead">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        &nbsp;My places
      </p>

      {props.places.length === 0 && (
        <Alert color="info">
          <FontAwesomeIcon icon={faLightbulb} />
          &nbsp;Looks like you did not set any place
        </Alert>
      )}

      <div className="text-center">
        <PlaceFormModal addPlace={props.addPlace} />
      </div>

      {props.places.length > 0 && <PlacesTable places={props.places} onDelete={props.deletePlace} />}
    </div>
  );
};

export default MyPlaces;
