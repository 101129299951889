import React from 'react';
import { Card, CardTitle, CardBody, CardText, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';
import styles from './ShuttleCard.module.css';
import moment from 'moment';
import { MSShuttle } from '../../../../../_clients/msShuttleClient';

interface Props {
  shuttles: MSShuttle[];
  lastUpdateDate: Date;
}

export class ShuttleCard extends React.Component<Props> {
  public render() {
    const { shuttles, lastUpdateDate } = this.props;
    return (
      <Card>
        <CardBody className={styles.cardBody}>
          <CardTitle className="text-center">
            <p className="lead">
              <FontAwesomeIcon icon={faBus} />
              &nbsp;Shuttles
            </p>
          </CardTitle>
          <div>
            <CardText>
              {shuttles.length === 0 && <span className="text-danger">No more shuttle</span>}
              {shuttles.length > 0 && <small className="text-muted">Next shuttles:</small>}
            </CardText>
            {shuttles.length > 0 && (
              <Table className="table" size="sm" striped={true}>
                <thead>
                  <tr>
                    <th>Time to shuttle</th>
                    <th>Time</th>
                    <th>Garigliano</th>
                    <th>Balard</th>
                  </tr>
                </thead>
                <tbody>
                  {shuttles.map((shuttle, i) => {
                    return (
                      <tr key={i}>
                        <td>{moment(shuttle.startDate).fromNow()}</td>
                        <td>{shuttle.startDate.toLocaleTimeString()}</td>
                        <td>{shuttle.gariglianoStopDate.toLocaleTimeString()}</td>
                        <td>{shuttle.balardStopDate.toLocaleTimeString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
          <CardText>
            <small className="text-muted">Last updated {lastUpdateDate.toLocaleString()}</small>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}
