import React from 'react';
import { Card, CardTitle, CardBody, CardText } from 'reactstrap';
import { VelibStation } from '../../../../../_clients/velibMetropoleClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faFlagCheckered, faBolt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { VelibStationsList } from './VelibStationsList';
import styles from './VelibCard.module.css';

interface Props {
  fromTitle: string;
  toTitle: string;
  fromStations: VelibStation[];
  toStations: VelibStation[];
  lastUpdateDate: Date;
  fetching: boolean;
}

export class VelibCard extends React.Component<Props> {
  public render() {
    const { fromTitle, toTitle, fromStations, toStations, lastUpdateDate, fetching } = this.props;

    const totalBikesFrom: number = fromStations.map((station) => station.mechanical).reduce((a, b) => a + b, 0);

    const totalEBikesFrom: number = fromStations.map((station) => station.ebike).reduce((a, b) => a + b, 0);

    const totalFreeTo: number = toStations.map((station) => station.numdocksavailable).reduce((a, b) => a + b, 0);
    return (
      <Card>
        <CardBody className={styles.cardBody}>
          <CardTitle className="text-center">
            <p className="lead">
              {fromTitle}
              &nbsp;
              <span className="text-muted">
                {totalBikesFrom}
                &nbsp;
                <FontAwesomeIcon icon={faBicycle} />
              </span>
              &nbsp; + &nbsp;
              <span className="text-muted">
                {totalEBikesFrom}
                &nbsp;
                <FontAwesomeIcon icon={faBolt} />
              </span>
              &nbsp; to &nbsp;
              {toTitle}
              &nbsp;
              <span className="text-muted">
                {totalFreeTo}
                &nbsp;
                <FontAwesomeIcon icon={faFlagCheckered} />
              </span>
            </p>
          </CardTitle>
          <div>
            <VelibStationsList title={fromTitle} stations={fromStations} mode="departure" />
          </div>
          <div>
            <VelibStationsList title={toTitle} stations={toStations} mode="arrival" />
          </div>
          <CardText>
            {fetching && (
              <small className="text-muted">
                <FontAwesomeIcon icon={faSpinner} spin={true} />
                &nbsp; loading...
              </small>
            )}
            {!fetching && <small className="text-muted">Last updated {lastUpdateDate.toLocaleString()}</small>}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}
