import { ApplicationState } from '../store/rootReducer';

export const stateVersion = '2.0.0.20191214';

export const loadState = (): ApplicationState | undefined => {
  try {
    const serializedState = localStorage.getItem('state_' + stateVersion);

    if (serializedState === null) {
      localStorage.clear();
      return undefined;
    }

    return JSON.parse(serializedState) as ApplicationState;
  } catch (error) {
    localStorage.clear();
    return undefined;
  }
};

export const saveState = (state: ApplicationState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state_' + stateVersion, serializedState);

    return true;
  } catch (error) {
    return false;
  }
};
