import React from 'react';
import { Alert } from 'reactstrap';
import { faLightbulb, faExclamationCircle, faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JourneysTable from './JourneysTable';
import JourneyFormModal from './JourneyFormModal';
import { Place } from '../../../../_models/Place';
import { Journey } from '../../../../_models/Journey';

interface Props {
  journeys: Journey[];
  places: Place[];
  addJourney: (journey: Journey) => void;
  deleteJourney: (idx: number) => void;
}

const MyJourneys = (props: Props) => {
  return (
    <div>
      <p className="lead">
        <FontAwesomeIcon icon={faRoute} />
        &nbsp;My journeys
      </p>

      {props.places.length < 2 && (
        <Alert color="warning">
          <FontAwesomeIcon icon={faExclamationCircle} />
          &nbsp;You need to create at least 2 places to create a journey
        </Alert>
      )}

      {props.places.length >= 2 && props.journeys.length === 0 && (
        <Alert color="info">
          <FontAwesomeIcon icon={faLightbulb} />
          &nbsp;Looks like you did not set any journey
        </Alert>
      )}

      {props.places.length >= 2 && (
        <div className="text-center">
          <JourneyFormModal places={props.places} addJourney={props.addJourney} />
        </div>
      )}

      {props.journeys.length > 0 && <JourneysTable journeys={props.journeys} onDelete={props.deleteJourney} />}
    </div>
  );
};

export default MyJourneys;
