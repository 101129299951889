import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PlaceForm from './PlaceForm';
import { Place } from '../../../../_models/Place';

interface Props {
  addPlace: (place: Place) => void;
}
const PlaceFormModal = (props: Props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button size="sm" outline={true} onClick={toggle}>
        Add a place
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader toggle={toggle}>Create a new place</ModalHeader>
        <ModalBody>
          <PlaceForm onComplete={toggle} addPlace={props.addPlace} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlaceFormModal;
