import { Reducer } from 'redux';
import { Journey } from '../../../_models/Journey';
import { PlacesActions } from '../../places/actions/placesActions';
import { UserActions } from '../../user/actions/userActions';
import { JourneysActions } from '../actions/journeysActions';

const defaultState: Journey[] = [];

export const reducer: Reducer<Journey[], JourneysActions | PlacesActions | UserActions> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case 'ADD_JOURNEY':
      return [...state, action.payload.journey];
    case 'DELETE_JOURNEY':
      return state.filter((v, i) => i !== action.payload.index);
    case 'DELETE_PLACE':
      return state.filter(
        (p) => p.fromPlaceName !== action.payload.placeKey && p.toPlaceName !== action.payload.placeKey
      );
    case 'LOAD_CONFIG_SUCCESS':
      return [...action.payload.state.journeys];
    default:
      return state;
  }
};
