import React from 'react';
import { connect } from 'react-redux';
import { Place } from '../../../_models/Place';
import MyPlaces from './components/MyPlaces';
import MyJourneys from './components/MyJourneys';
import { ApplicationState } from '../../../store/rootReducer';
import { PayloadAction } from '../../../store/PayloadAction';
import { RouteComponentProps, withRouter } from 'react-router';
import { Journey } from '../../../_models/Journey';
import { addPlace, deletePlace } from '../../../store/places/actions/placesActions';
import { addJourney, deleteJourney } from '../../../store/journeys/actions/journeysActions';
import ConfigSharing from './components/ConfigSharing';
import { loadConfigAsync, saveConfigAsync } from '../../../_clients/configClient';
import { loadConfigSuccess, shareConfig } from '../../../store/user/actions/userActions';
import { ThunkDispatch } from 'redux-thunk';

interface Props extends RouteComponentProps<{}> {
  applicationState: ApplicationState;
  places: Place[];
  journeys: Journey[];
  addPlace: (place: Place) => void;
  deletePlace: (placeKey: string) => void;
  addJourney: (journey: Journey) => void;
  deleteJourney: (index: number) => void;
  onShareConfig: (code: string, validUntil: number) => void;
  onLoadConfig: (state: ApplicationState) => void;
}

interface State {
  configLoading: boolean;
  configUploading: boolean;
}

class SettingsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      configLoading: false,
      configUploading: false,
    };
  }

  public render() {
    return (
      <div>
        <MyPlaces addPlace={this.props.addPlace} deletePlace={this.props.deletePlace} places={this.props.places} />
        <MyJourneys
          addJourney={this.props.addJourney}
          deleteJourney={this.props.deleteJourney}
          journeys={this.props.journeys}
          places={this.props.places}
        />
        <ConfigSharing
          config={this.props.applicationState.user.sharedConfig}
          configLoading={this.state.configLoading}
          configUploading={this.state.configUploading}
          onLoad={this.onLoadConfig}
          onShare={this.onShareConfig}
        />
      </div>
    );
  }

  onShareConfig = () => {
    this.setState({
      configUploading: true,
    });

    saveConfigAsync(this.props.applicationState)
      .then((response) => {
        this.props.onShareConfig(response.code, new Date(response.validUntil).getTime());
        this.setState({
          configUploading: false,
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          configUploading: false,
        });
      });
  };

  onLoadConfig = (code: string) => {
    this.setState({
      configLoading: true,
    });

    loadConfigAsync(code)
      .then((response) => {
        this.props.onLoadConfig(JSON.parse(response));
        this.setState({
          configLoading: false,
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          configLoading: false,
        });
      });
  };
}

function mapStateToProps(applicationState: ApplicationState) {
  return {
    applicationState,
    places: Object.values(applicationState.places),
    journeys: applicationState.journeys,
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<ApplicationState, void, PayloadAction>) => {
  return {
    addPlace: (place: Place) => dispatch(addPlace(place)),
    deletePlace: (placeKey: string) => dispatch(deletePlace(placeKey)),
    addJourney: (journey: Journey) => dispatch(addJourney(journey)),
    deleteJourney: (index: number) => dispatch(deleteJourney(index)),
    onShareConfig: (code: string, validUntil: number) => dispatch(shareConfig(code, validUntil)),
    onLoadConfig: (state: ApplicationState) => dispatch(loadConfigSuccess(state)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsPage));
