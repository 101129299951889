import React, { useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigState } from '../../../../store/user';

interface Props {
  config?: ConfigState;
  configLoading: boolean;
  configUploading: boolean;
  onShare: () => void;
  onLoad: (id: string) => void;
}

const ConfigSharing = (props: Props) => {
  const [code, setCode] = useState('');
  const isConfigValid = props.config ? Date.now() <= props.config.validUntil : false;
  const isLoading = props.configLoading || props.configUploading;
  return (
    <div className="text-center">
      {isConfigValid && (
        <p className="lead">
          Share code: {props.config!.code} (expire at: {new Date(props.config!.validUntil).toLocaleString()})
        </p>
      )}
      <Input value={code} onChange={(e) => setCode(e.currentTarget.value)} />
      <ButtonGroup>
        <Button color="secondary" disabled={isConfigValid || isLoading} onClick={props.onShare}>
          <FontAwesomeIcon icon={faShare} spin={props.configUploading} /> Share config
        </Button>
        <Button color="secondary" disabled={isLoading} outline={true} onClick={() => props.onLoad(code)}>
          <FontAwesomeIcon icon={faDownload} spin={props.configLoading} /> Load config
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ConfigSharing;
