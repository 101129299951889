import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { Place } from '../../../../_models/Place';

interface Props {
  places: Place[];
  onDelete: (key: string) => void;
}

const PlacesTable = (props: Props) => {
  return (
    <Table className="table" size="sm" striped={true}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Velib station</th>
          <th>Train station</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.places.map((place, i) => {
          return (
            <tr key={i}>
              <td>{place.name}</td>
              <td>{place.preferredVelibStation && place.preferredVelibStation.name}</td>
              <td>{place.preferredTrainStation && place.preferredTrainStation.name}</td>
              <td>
                <Button size="sm" color="danger" onClick={() => props.onDelete(place.name)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PlacesTable;
