import { createStore, applyMiddleware, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { ApplicationState, rootReducer } from './rootReducer';
import { PayloadAction } from './PayloadAction';
import { loadState } from '../_services/localStorageServices';

export default function configureStore() {
  const middlewares: Middleware[] = [];
  middlewares.push(thunkMiddleware);

  const store = createStore<ApplicationState, PayloadAction, {}, {}>(
    rootReducer,
    loadState(),
    applyMiddleware(...middlewares)
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
