import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import VelibContainer from './velib/VelibContainer';
import TrainsContainer from './trains/TrainsContainer';
import { Row, Col, Alert, Form, FormGroup, Input } from 'reactstrap';
import ShuttleContainer from './shuttle/ShuttleContainer';
import { Journey } from '../../../../_models/Journey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../../../../store/rootReducer';
import { Place } from '../../../../_models/Place';
import { selectJourney } from '../../../../store/user/actions/userActions';
import { PayloadAction } from '../../../../store/PayloadAction';

interface Props extends RouteComponentProps<{}> {
  selectedJourneyName?: string;
  places: { [key: string]: Place };
  journeys: Journey[];
  selectJourney: (name: string) => void;
}

class JourneyContainer extends React.Component<Props> {
  componentDidMount() {
    const { journeys, selectedJourneyName } = this.props;

    if (!selectedJourneyName && journeys && journeys.length > 0) {
      const defaultJourney = journeys[0].name;
      this.props.selectJourney(defaultJourney);
    }
  }

  public render() {
    const { journeys, selectedJourneyName, places } = this.props;

    const selectedJourney = journeys.find((j) => j.name === selectedJourneyName);

    const fromPlace = selectedJourney && places[selectedJourney.fromPlaceName];

    return (
      <>
        {journeys.length === 0 && (
          <Alert color="info">
            <FontAwesomeIcon icon={faLightbulb} />
            &nbsp;Looks like you did not set any journey &nbsp;
            <a href="/settings" className="alert-link">
              Go to settings page
            </a>
          </Alert>
        )}
        {journeys.length > 0 && (
          <Form>
            <FormGroup>
              <Input
                id="inputJourneyName"
                name="inputJourneyName"
                onChange={this.handleChange}
                value={this.props.selectedJourneyName}
                type="select"
              >
                {this.props.journeys.map((j) => {
                  return (
                    <option key={j.name} value={j.name}>
                      {j.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Form>
        )}
        {journeys.length > 0 && selectedJourney && (
          <>
            <Row>
              {fromPlace && fromPlace.preferredVelibStation && (
                <Col sm="12" md="6" lg="auto">
                  <VelibContainer />
                </Col>
              )}
              {fromPlace && fromPlace.preferredTrainStation && (
                <Col sm="12" md="6" lg="auto">
                  <TrainsContainer />
                </Col>
              )}
              {fromPlace && fromPlace.preferredShuttleService && fromPlace.preferredShuttleService === 'MSShuttle' && (
                <Col sm="12" md="6" lg="auto">
                  <ShuttleContainer />
                </Col>
              )}
            </Row>
          </>
        )}
      </>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.props.selectJourney(value);
  };
}

function mapStateToProps(applicationState: ApplicationState) {
  return {
    places: applicationState.places,
    journeys: applicationState.journeys,
    selectedJourneyName: applicationState.user.selectedJourneyName,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<PayloadAction>) => {
  return {
    selectJourney: (name: string) => dispatch(selectJourney(name)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JourneyContainer));
