import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Place } from '../../../../_models/Place';
import JourneyForm from './JourneyForm';
import { Journey } from '../../../../_models/Journey';

interface Props {
  places: Place[];
  addJourney: (journey: Journey) => void;
}
const PlaceFormModal = (props: Props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button size="sm" outline={true} onClick={toggle}>
        Add a journey
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader toggle={toggle}>Create a new journey</ModalHeader>
        <ModalBody>
          <JourneyForm onComplete={toggle} addJourney={props.addJourney} places={props.places} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PlaceFormModal;
