// https://velib-metropole-opendata.smoove.pro/opendata/Velib_Metropole/station_information.json
import velibStations from './velibStations.json';
import { GeoPosition } from '../_models/GeoPosition.js';

export const getVelibStationInfo = (stationId: number): StationInformation => {
  return velibStations.data.stations.find((s) => s.station_id === stationId) as StationInformation;
};

export const getVelibStations = (): StationInformation[] => {
  const rawVelibStations: VelibStationsListContract = velibStations;
  const stations: StationContract[] = rawVelibStations.data.stations;
  stations.sort((a, b) => a.name.localeCompare(b.name));
  return stations as StationInformation[];
};

export const buildGeoFilter = (geoPosition: GeoPosition, distance?: number): string => {
  return `${geoPosition.latitude},${geoPosition.longitude},${distance || 300}`;
};

export const getVelibStationsAroundAsync = async (
  geoPosition: GeoPosition,
  distance?: number
): Promise<VelibStation[]> => {
  const geoFilter = buildGeoFilter(geoPosition, distance);
  const url = `https://opendata.paris.fr/api/records/1.0/search/?dataset=velib-disponibilite-en-temps-reel&geofilter.distance=${geoFilter}`;
  const response = await fetch(url, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error(response.statusText || `${response.status}`);
  }

  const velibResponseContract: VelibResponseContract = await response.json();

  return velibResponseContract.records.map((record) => record.fields as VelibStation);
};

export const defaultStations: { geoFilter: string; displayName: string }[] = [
  {
    geoFilter: '48.8343883676388%2C2.2656820714473724%2C600',
    displayName: 'Quai du Président Roosevelt - Pont Aval',
  },
  {
    geoFilter: '48.82269884297945%2C2.2686498274963633%2C300',
    displayName: 'Victor Cresson - Telles de la Poterie',
  },
  {
    geoFilter: '48.828156251574946%2C2.358513532903332%2C500',
    displayName: "Place d'Italie",
  },
  {
    geoFilter: '48.84021933807409%2C2.263834191152959%2C300',
    displayName: 'Versailles - Claude Terrasse',
  },
];

// models
export interface VelibStation {
  ebike: number;
  capacity: number;
  name: string;
  nom_arrondissement_communes: string;
  numbikesavailable: number;
  is_installed: string;
  is_renting: string;
  mechanical: number;
  stationcode: string;
  dist: string;
  coordonnees_geo: number[];
  numdocksavailable: number;
  duedate: Date;
  is_returning: string;
}

export interface StationInformation {
  station_id: number;
  name: string;
  lat: number;
  lon: number;
  capacity: number;
  stationCode: string;
  rental_methods?: string[];
}

// contracts
interface VelibStationsListContract {
  lastUpdatedOther: number;
  ttl: number;
  data: DataContract;
}

interface DataContract {
  stations: StationContract[];
}

interface StationContract {
  station_id: number;
  name: string;
  lat: number;
  lon: number;
  capacity: number;
  stationCode: string;
  rental_methods?: string[];
}

interface ParametersContract {
  dataset: string;
  timezone: string;
  rows: number;
  format: string;
  'geofilter.distance': string[];
}

interface FieldsContract {
  ebike: number;
  capacity: number;
  name: string;
  nom_arrondissement_communes: string;
  numbikesavailable: number;
  is_installed: string;
  is_renting: string;
  mechanical: number;
  stationcode: string;
  dist: string;
  coordonnees_geo: number[];
  numdocksavailable: number;
  duedate: Date;
  is_returning: string;
}

interface GeometryContract {
  type: string;
  coordinates: number[];
}

interface RecordContract {
  datasetid: string;
  recordid: string;
  fields: FieldsContract;
  geometry: GeometryContract;
  record_timestamp: Date;
}

interface VelibResponseContract {
  nhits: number;
  parameters: ParametersContract;
  records: RecordContract[];
}
